<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="aboutUs">
				<div class="au_div">
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content1.title')}}</div>
						<p class="au_di_cd_content">
							650,000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content1.text')}}</small>
						</p>
					</div>
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content2.title')}}</div>
						<p class="au_di_cd_content">
							3000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content2.text')}}</small>
						</p>
					</div>
		
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content3.title')}}</div>
						<p class="au_di_cd_content">
							500
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content3.text')}}</small>
						</p>
					</div>
				</div>
		
				<div class="au_div">
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content4.title')}}</div>
						<p class="au_di_cd_content">
							80,000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content4.text')}}</small>
						</p>
					</div>
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content5.title')}}</div>
						<p class="au_di_cd_content">
							200
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content5.text')}}</small>
						</p>
					</div>
				</div>
		
				<div class="introFlag">
					<div class="if_div">
						<h3 class="if_di_title">{{$t('aboutUs.aboutUs.title')}}</h3>
					</div>
				</div>
				
				<div class="au_contentDiv">
					<div class="au_cd_left">
						<img class="au_dc_le_img" :src="img" />
					</div>
					<div class="au_cd_right">
						<div class="au_cd_ri_content1">
							{{$t('aboutUs.aboutUs.content6')}}
						</div>
						<div class="au_cd_ri_content2">
							{{$t('aboutUs.aboutUs.content7')}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="aboutUs2">
				<h3 style="font-size: 21px;text-align: center;padding-bottom: 20px;">{{$t('aboutUs.aboutUs.title')}}</h3>
				<div class="au_div">
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content1.title')}}</div>
						<p class="au_di_cd_content">
							650,000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content1.text')}}</small>
						</p>
					</div>
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content2.title')}}</div>
						<p class="au_di_cd_content">
							3000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content2.text')}}</small>
						</p>
					</div>
		
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content3.title')}}</div>
						<p class="au_di_cd_content">
							500
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content3.text')}}</small>
						</p>
					</div>
					
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content4.title')}}</div>
						<p class="au_di_cd_content">
							80,000
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content4.text')}}</small>
						</p>
					</div>
					
					<div class="au_di_contentDiv">
						<div class="au_di_cd_title">{{$t('aboutUs.aboutUs.content5.title')}}</div>
						<p class="au_di_cd_content">
							200
							<small class="au_di_cd_ct_text">{{$t('aboutUs.aboutUs.content5.text')}}</small>
						</p>
					</div>
				</div>
		
				<!-- <div class="introFlag">
					<div class="if_div">
						<h3 class="if_di_title">{{$t('aboutUs.aboutUs.title')}}</h3>
					</div>
				</div> -->
				
				<div class="au_contentDiv">
					<div class="au_cd_left">
						<img class="au_dc_le_img" :src="img" />
					</div>
					<div class="au_cd_right">
						<div class="au_cd_ri_content1">
							{{$t('aboutUs.aboutUs.content6')}}
						</div>
						<div class="au_cd_ri_content2">
							{{$t('aboutUs.aboutUs.content7')}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img: process.env.VUE_APP_OSS_URL + '/factoryDisplay/adout2.jpg',
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.aboutUs{
			position: relative;
			padding: 40px 200px;
			
			.au_div{
				margin-bottom: 20px;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					padding-right: 40px;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 48px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				display: flex;
				
				.au_cd_left{
					width: 39%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 60%;
					padding-left: 2%;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 335px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 258px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.aboutUs{
			position: relative;
			padding: 40px 200px;
			
			.au_div{
				margin-bottom: 20px;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					padding-right: 40px;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 48px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				display: flex;
				
				.au_cd_left{
					width: 39%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 60%;
					padding-left: 2%;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 335px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 258px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.aboutUs{
			position: relative;
			padding: 40px 140px;
			
			.au_div{
				margin-bottom: 20px;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					padding-right: 40px;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 48px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				display: flex;
				
				.au_cd_left{
					width: 39%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 60%;
					padding-left: 2%;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 188px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 140px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.aboutUs{
			position: relative;
			padding: 40px 30px;
			
			.au_div{
				margin-bottom: 20px;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					padding-right: 40px;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 48px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				display: flex;
				
				.au_cd_left{
					width: 39%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 60%;
					padding-left: 2%;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 62px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 36px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.aboutUs2{
			position: relative;
			padding: 40px 30px;
			
			.au_div{
				margin-bottom: 20px;
				display: flex;
				flex-wrap: wrap;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 30px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				
				.au_cd_left{
					width: 100%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 100%;
					padding-top: 20px;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 62px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 36px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
	@media only screen and (max-width:767px) {
		.aboutUs2{
			position: relative;
			padding: 40px 30px;
			
			.au_div{
				margin-bottom: 20px;
				display: flex;
				flex-wrap: wrap;
				
				.au_di_contentDiv{
					padding: 0 45px;
					min-width: 205px;
					margin-bottom: 20px;
					box-sizing: border-box;
					display: inline-block;
					border-right: 1px solid #efefef;
					
					.au_di_cd_title{
						margin-bottom: 5px;
						font-size: 15px;
						color: #666666;
					}
					
					.au_di_cd_content{
						font-size: 30px;
						line-height: 1;
						color: #555;
						font-family: Akrobat;
						font-weight: bold;
						
						.au_di_cd_ct_text{
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
			
			.au_contentDiv{
				
				.au_cd_left{
					width: 100%;
					margin-right: 1%;
					
					.au_dc_le_img{
						width: 100%;
						height: 100%;
					}
				}
				
				.au_cd_right{
					width: 100%;
					padding-top: 20px;
					
					.au_cd_ri_content1{
						line-height: 30px;
					}
					
					.au_cd_ri_content2{
						padding-top: 20px;
						line-height: 30px;
					}
				}
			}
		}
		
		.introFlag {
			position: absolute;
			top: 60px;
			right: 0;
			z-index: 1;
			
			.if_div{
				margin-right: 62px;
				padding: 15px 0;
				position: relative;
				z-index: 2;
				text-align: right;
				background-color: #fff;
				color: #333;
				
				.if_di_title{
					font-size: 30px;
				}
			}
		}
		
		.introFlag:before {
			display: inline-block;
			content: '';
			width: 344px;
			height: 470px;
			border: 10px solid #eeeeee;
			background: transparent;
			position: absolute;
			top: -260px;
			right: 36px;
			z-index: 1;
			box-sizing: border-box;
		}
	}
	
</style>

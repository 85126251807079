<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<banner></banner>
		<about-us :windowWidth="windowWidth" :windowHeight="windowHeight"></about-us>
		<honor :windowWidth="windowWidth" :windowHeight="windowHeight"></honor>
		<core-concepts :windowWidth="windowWidth" :windowHeight="windowHeight"></core-concepts>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import banner from './components/aboutUs/banner.vue';
	import aboutUs from './components/aboutUs/aboutUs.vue';
	import honor from './components/aboutUs/honor.vue';
	import coreConcepts from './components/aboutUs/coreConcepts.vue'; //引入核心理念组件
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			banner,
			aboutUs,
			honor,
			coreConcepts,
			bottom,
			right
		},
		data(){
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less">
	
</style>
<template>
	<div>
		<div class="coreConcepts">
			<div class="cc_div">
				<div class="cc_div_title">
					<div class="title1">{{$t('aboutUs.coreConcepts.title')}}</div>
					<div class="title2">{{$t('aboutUs.coreConcepts.content')}}</div>
				</div>
				<div class="cc_div_content" v-for="(item,index) in $t('aboutUs.coreConcepts.list')" :key="index">
					<div class="cc_div_ct_title">{{item.title}}</div>
					<div class="cc_div_ct_content">{{item.content}}</div>
				</div>
				<div class="cc_div_ct_mask"></div>
			</div>
			
			<div class="imageDiv"
				:style="'background-image:' + `url('http://static601.yun300.cn/img/qy_bg.jpg?tenantId=252768&viewType=1&v=1678089441000')`">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			height: 100%;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-size: cover;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		.coreConcepts{
			position: relative;
			height: 100%;
			
			.cc_div{
				position: relative;
				width: 55%;
				height: 100%;
				padding: 100px 80px 100px 200px;
				margin: 40px 0;
				
				.cc_div_title{
					position: relative;
					z-index: 9;
					padding-bottom: 60px;
					
					.title1{
						color: #fff;
						font-weight: bold;
						font-size: 32px;
					}
					
					.title2{
						// font-size: 28px;
						color: #fff;
						padding-top: 20px;
					}
				}
				
				.cc_div_content{
					position: relative;
					z-index: 9;
					padding: 20px 0;
					
					.cc_div_ct_title{
						color: #fff;
						font-weight: bold;
						font-size: 28px;
					}
					
					.cc_div_ct_content{
						color: #fff;
						padding-top: 20px;
						// font-weight: bold;
						line-height: 2;
					}
				}
				
				.cc_div_ct_mask{
					background: #000;
					opacity: .5;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			height: 100%;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-size: cover;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		.coreConcepts{
			position: relative;
			height: 100%;
			
			.cc_div{
				position: relative;
				width: 55%;
				height: 100%;
				padding: 100px 80px 100px 200px;
				margin: 40px 0;
				
				.cc_div_title{
					position: relative;
					z-index: 9;
					padding-bottom: 60px;
					
					.title1{
						color: #fff;
						font-weight: bold;
						font-size: 32px;
					}
					
					.title2{
						// font-size: 28px;
						color: #fff;
						padding-top: 20px;
					}
				}
				
				.cc_div_content{
					position: relative;
					z-index: 9;
					padding: 20px 0;
					
					.cc_div_ct_title{
						color: #fff;
						font-weight: bold;
						font-size: 28px;
					}
					
					.cc_div_ct_content{
						color: #fff;
						padding-top: 20px;
						// font-weight: bold;
						line-height: 2;
					}
				}
				
				.cc_div_ct_mask{
					background: #000;
					opacity: .5;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
		
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			height: 100%;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-size: cover;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		.coreConcepts{
			position: relative;
			height: 100%;
			
			.cc_div{
				position: relative;
				width: 70%;
				height: 100%;
				padding: 100px 80px 100px 100px;
				margin: 40px 0;
				
				.cc_div_title{
					position: relative;
					z-index: 9;
					padding-bottom: 60px;
					
					.title1{
						color: #fff;
						font-weight: bold;
						font-size: 32px;
					}
					
					.title2{
						// font-size: 28px;
						color: #fff;
						padding-top: 20px;
					}
				}
				
				.cc_div_content{
					position: relative;
					z-index: 9;
					padding: 20px 0;
					
					.cc_div_ct_title{
						color: #fff;
						font-weight: bold;
						font-size: 28px;
					}
					
					.cc_div_ct_content{
						color: #fff;
						padding-top: 20px;
						// font-weight: bold;
						line-height: 2;
					}
				}
				
				.cc_div_ct_mask{
					background: #000;
					opacity: .5;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
		
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			height: 100%;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-size: cover;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		.coreConcepts{
			position: relative;
			height: 100%;
			
			.cc_div{
				position: relative;
				width: 70%;
				height: 100%;
				padding: 100px 80px 100px 60px;
				margin: 40px 0;
				
				.cc_div_title{
					position: relative;
					z-index: 9;
					padding-bottom: 60px;
					
					.title1{
						color: #fff;
						font-weight: bold;
						font-size: 32px;
					}
					
					.title2{
						// font-size: 28px;
						color: #fff;
						padding-top: 20px;
					}
				}
				
				.cc_div_content{
					position: relative;
					z-index: 9;
					padding: 20px 0;
					
					.cc_div_ct_title{
						color: #fff;
						font-weight: bold;
						font-size: 28px;
					}
					
					.cc_div_ct_content{
						color: #fff;
						padding-top: 20px;
						// font-weight: bold;
						line-height: 2;
					}
				}
				
				.cc_div_ct_mask{
					background: #000;
					opacity: .5;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
		
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.imageDiv {
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				height: 100%;
				// width: 100%;
				// overflow: hidden;
				// overflow-y: scroll;
				background-repeat: no-repeat;
				background-attachment: fixed;
				background-size: cover;
				z-index: 1;
				// background-position: left center;
				// background-size: 100% auto;
				// background-color: #fff;
				// background-image: url('@/assets/img/deba.png');
				// background-attachment: scroll;
				// animation: myfirst 2s;
				// -moz-animation: myfirst 2s;
				// /* Firefox */
				// -webkit-animation: myfirst 2s;
				// /* Safari 和 Chrome */
				// -o-animation: myfirst 2s;
				/* Opera */
			}
			
			.coreConcepts{
				position: relative;
				height: 100%;
				
				.cc_div{
					position: relative;
					width: 70%;
					height: 100%;
					padding: 100px 80px 100px 60px;
					margin: 40px 0;
					
					.cc_div_title{
						position: relative;
						z-index: 9;
						padding-bottom: 60px;
						
						.title1{
							color: #fff;
							font-weight: bold;
							font-size: 22px;
						}
						
						.title2{
							font-size: 16px;
							color: #fff;
							padding-top: 14px;
						}
					}
					
					.cc_div_content{
						position: relative;
						z-index: 9;
						padding: 20px 0;
						
						.cc_div_ct_title{
							color: #fff;
							font-weight: bold;
							font-size: 22px;
						}
						
						.cc_div_ct_content{
							color: #fff;
							padding-top: 20px;
							// font-weight: bold;
							line-height: 2;
							font-size: 16px;
						}
					}
					
					.cc_div_ct_mask{
						background: #000;
						opacity: .5;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 2;
					}
				}
			}
	}
	
	@media only screen and (max-width:767px) {
		
			
				.imageDiv {
					position: absolute;
					width: 100%;
					bottom: 0;
					left: 0;
					height: 100%;
					// width: 100%;
					// overflow: hidden;
					// overflow-y: scroll;
					background-repeat: no-repeat;
					background-attachment: fixed;
					background-size: cover;
					z-index: 1;
					// background-position: left center;
					// background-size: 100% auto;
					// background-color: #fff;
					// background-image: url('@/assets/img/deba.png');
					// background-attachment: scroll;
					// animation: myfirst 2s;
					// -moz-animation: myfirst 2s;
					// /* Firefox */
					// -webkit-animation: myfirst 2s;
					// /* Safari 和 Chrome */
					// -o-animation: myfirst 2s;
					/* Opera */
				}
				
				.coreConcepts{
					position: relative;
					height: 100%;
					
					.cc_div{
						position: relative;
						width: 100%;
						height: 100%;
						padding: 100px 80px 100px 80px;
						margin: 40px 0;
						
						.cc_div_title{
							position: relative;
							z-index: 9;
							padding-bottom: 60px;
							
							.title1{
								color: #fff;
								font-weight: bold;
								font-size: 22px;
							}
							
							.title2{
								font-size: 16px;
								color: #fff;
								padding-top: 14px;
							}
						}
						
						.cc_div_content{
							position: relative;
							z-index: 9;
							padding: 20px 0;
							
							.cc_div_ct_title{
								color: #fff;
								font-weight: bold;
								font-size: 22px;
							}
							
							.cc_div_ct_content{
								color: #fff;
								padding-top: 20px;
								// font-weight: bold;
								line-height: 2;
								font-size: 16px;
							}
						}
						
						.cc_div_ct_mask{
							background: #000;
							opacity: .5;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 2;
						}
					}
				}
		
	}
</style>
<template>
	<div>
		<div class="banner" :style="'background-image:' + `url(${img})`">
			<div class="ba_titleDiv">
				<span style="color: #f54a16;">{{$t('factoryDisplay.sunbathing.title1')}}<span style="color: #fff;">{{$t('factoryDisplay.sunbathing.title2')}}</span></span>
			</div>
			<div class="ba_text">
				{{$t('factoryDisplay.sunbathing.text')}}
			</div>
			<div class="ba_img">
				<img class="imgDiv" src="../../../../assets/img/evalua-icon.png" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/evaluation-bannerBg.jpg'
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 140px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				padding-top: 20px;
			}
			
			.ba_img{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				padding-top: 20px;
				
				.imgDiv{
					width: 100%;
				}
			}
		}
	}
	
</style>